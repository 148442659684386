<template>
  <div class="app-container">
    <ExtendedCrudTable
      ref="table"
      entity="Document"
      :entity-store="entityStore"
      :columns="columns"
      :disabled="!hasReadPermission"
      parent-id-filter-field="dossierID"
      :generate-summary="generateSummary"
      :definition="definition"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :parent-i-d="dossierID"
    />
    <br />
    <hr />
    <RequestDocumentForm :dossier-i-d="dossierID" @documentGenerated="documentGenerated" />
  </div>
</template>
<script>
import crudIndexMixin from '@/mixins/crud-index';
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import { hasReadPermissionForCode } from '@/utils/permission';
import formMixin from '@/pages/dossiers/documents/form-mixin';
import { getFormDefinition, createItemFromFormData, createFormDataFromItem } from './form';
import RequestDocumentForm from './RequestDocumentForm';

const ExtendedCrudTable = {
  itemFilter: l => l.dossierID === this.dossierID,
  name: 'ExtendedCrudTable',
  extends: EmbeddedCrudTable,
  methods: {
    showCreate() {
      if (this.disabled) {
        return false;
      }
      this.$router.push({ name: 'CreateDocument', params: { dossierID: this.$route.params.id } }); // .catch(err => {        console.log('minor router error');      });
      this.$emit('create');
    },
    showEdit(id) {
      if (this.disabled) {
        return false;
      }
      const docid = this.$route.params.id + ',' + id;
      this.$router.push({ name: 'EditDocument', params: { id: id, identifier: docid } }); // .catch(err => {        console.log('minor router error');      });
      this.$emit('edit');
    }
  }
};

export default {
  components: {
    ExtendedCrudTable,
    RequestDocumentForm
  },
  option: {
    allowCreate: false
  },
  mixins: [crudIndexMixin, formMixin],
  data() {
    return {
      columns: [
        { field: 'name', label: 'common.name' },
        { field: 'documentTypeName', label: 'document.documentType' },
        { field: 'description', label: 'document.description' },
        { field: 'createDate', label: 'document.createDate', type: 'date' },
        { field: 'documentStatusName', label: 'document.status' },
        { field: 'rejectReason', label: 'document.rejectReason' }
      ]
    };
  },
  computed: {
    hasReadPermission() {
      return hasReadPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    definition() {
      return getFormDefinition(this);
    },
    dossierID() {
      return parseInt(this.$route.params.id);
    }
  },
  methods: {
    getDependencies(data) {
      return [
        { entity: 'documentType' },
        { entity: 'documentStatus' },
        { entity: 'dossier' },
        // { entity: 'loan' },
        { entity: 'template' }
      ];
    },
    showCreate() {
      if (this.disabled) {
        return false;
      }
      this.editorProps = { isEdit: false, getDependencies: this.getDependencies };
      this.dialogVisible = true;
      this.dialogTitle = this.$t('route.create' + this.entity);
      if (this.$refs.dialog) {
        this.$refs.dialog.clearForm();
      }
    },
    showEdit(id) {
      if (this.disabled) {
        return false;
      }
      this.dialogTitle = this.$t('route.edit' + this.entity);
      this.editorProps = {
        id,
        isEdit: true,
        fetchById: this.fetchById,
        disabled: !this.hasEditPermission,
        getDependencies: this.getDependencies
      };
      this.dialogVisible = true;
    },
    generateSummary({ documentid, name }) {
      let summary = `${documentid}`;

      if (name) {
        summary += ` (${name})`;
      }

      return summary;
    },
    documentGenerated() {
      this.$refs.table.refresh();
    }
  },
  createItemFromFormData() {
    return createItemFromFormData(this);
  },
  createFormDataFromItem
};
</script>

<style scoped></style>
