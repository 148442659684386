<template>
  <el-form :inline="true">
    <el-form-item>
      <DSESelect
        name="loanSelect"
        :value="null"
        :placeholder="$t('requestDocument.loanPlaceholder')"
        :options="loanOptions"
        :loading="loanIsLoading"
        value-field="id"
        label-field="displayName"
        :required="true"
        :default-first-option="true"
        @input="loanSelected"
      />
      <el-form-item>
        <el-table ref="templatelist" :data="templateOptions" @selection-change="templatesSelected">
          <el-table-column type="selection" width="55" />
          <el-table-column property="id" />
          <el-table-column property="name" label="Sjabloon" width="300" />
        </el-table>
      </el-form-item>
    </el-form-item>
    <el-form-item>
      <BaseButton
        :caption="$t('requestDocument.generate')"
        :loading="loading"
        :icon-only="false"
        :disabled="generatedisabled"
        @click="onSubmit"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import DSESelect from '@/components/DSE/DSESelect';
import BaseButton from '@/components/crud/BaseButton';
import { saveBlobAs } from '@/utils';

export default {
  components: {
    DSESelect,
    BaseButton
  },
  props: {
    dossierID: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      generatedisabled: true,
      templateIds: [],
      loanID: 0
    };
  },
  computed: {
    templateOptions() {
      const templates = this.$store.getters['template/loadedItems'];
      return templates;
    },
    templateIsLoading() {
      return this.$store.getters['template/isLoading'];
    },
    loanOptions() {
      const loans = [...this.$store.getters['loan/loadedItems'].filter(l => l.dossierID === this.dossierID)];
      return loans;
    },
    loanIsLoading() {
      return this.$store.getters['loan/isLoading'];
    }
  },
  async created() {
    await Promise.all([this.$store.dispatch('template/getItems'), this.$store.dispatch('loan/getItems')]);
  },
  methods: {
    async onSubmit() {
      const documentRequest = {
        templateIds: this.templateIds,
        LoanID: this.loanID,
        DossierID: this.dossierID
      };

      await this.requestDocuments(documentRequest);
    },
    async requestDocuments(request) {
      try {
        this.loading = true;
        const results = await this.$store.dispatch('document/requestDocuments', request);
        const newdocuments = Array.from(results.data);
        newdocuments.forEach(result => {
          const byteArray = new Buffer(result.content.replace(/^[\w\d;:\/]+base64\,/g, ''), 'base64');
          // The substitution using regex is not mandatory, but it can be utilized to address the prefix, such as in the situation of a dataurl string.
          // https://copyprogramming.com/howto/javascript-javascript-convert-convert-base64-to-byte-array
          saveBlobAs(byteArray, result.fileName, result.mediaType);
        });
        this.$emit('documentGenerated');
      } finally {
        this.$refs.templatelist.clearSelection();
        this.loading = false;
      }
    },
    templatesSelected(selection) {
      if (selection.length > 0 && this.loanID > 0) {
        this.templateIds = selection;
        this.generatedisabled = false;
      } else {
        this.generatedisabled = true;
      }
    },
    loanSelected(loanID) {
      this.loanID = loanID;
      if (this.templateIds.length > 0 && this.loanID > 0) {
        this.generatedisabled = false;
      } else {
        this.generatedisabled = true;
      }
    }
  }
};
</script>

<style scoped></style>
