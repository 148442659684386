<template>
  <tabbed-editor-header-card :title="title" class="dossier_header">
    <p>
      Dossiernummer: {{ dossiernumber }} - <b>{{ fullname }}</b> <br />
      Telefoonnummer(s): {{ phonenumbers }}<br />
      email: {{ email }}
    </p>
  </tabbed-editor-header-card>
</template>

<script>
import TabbedEditorHeaderCard from '@/components/crud/TabbedEditorHeaderCard';

export default {
  components: {
    TabbedEditorHeaderCard
  },
  props: {
    dossier: Object
  },
  computed: {
    title() {
      const title = 'Dossier voor';
      return title;
    },
    fullname: {
      get() {
        let fullname = this.dossier.initials + ' ';
        if (this.dossier.middleName) {
          fullname = fullname + this.dossier.middleName + ' ';
        }
        fullname = fullname + this.dossier.lastName;
        return fullname;
      }
    },
    birthdate: {
      get() {
        const date = new Date(this.dossier.dateOfBirth);
        return date.toLocaleDateString();
      }
    },
    phonenumbers: {
      get() {
        let numbers = '';
        let separator = '';
        if (this.dossier.mobile) {
          numbers = this.dossier.mobile;
          separator = ' / ';
        }
        if (this.dossier.telephone) {
          numbers = numbers + separator + this.dossier.telephone;
        }
        return numbers;
      }
    },
    email: {
      get() {
        return this.dossier.email;
      }
    },
    dossiernumber: {
      get() {
        return this.dossier.id;
      }
    }
  }
};
</script>
