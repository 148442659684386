<template>
  <el-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <el-card v-loading="loading">
      <div>
        <slot name="supertitle" />
        <h3 v-if="title">{{ title }}</h3>
        <slot />
        <p v-if="body">{{ body }}</p>
      </div>
      <div v-if="links.length" class="footer clearfix">
        <span v-for="(link, index) in links" :key="index">
          <router-link :to="link.to">
            <el-button class="header-button" :icon="getIconFromLink(link)" type="text">
              {{ $t(link.caption) }}
            </el-button>
          </router-link>
        </span>
      </div>
    </el-card>
  </el-col>
</template>

<script>
export default {
  props: {
    title: String,
    body: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    xs: {
      type: Number,
      default: 24
    },
    sm: {
      type: Number,
      default: 18
    },
    md: {
      type: Number,
      default: 12
    },
    lg: {
      type: Number,
      default: 8
    },
    xl: {
      type: Number,
      default: 6
    }
  },
  methods: {
    getIconFromLink(link) {
      switch (link.type) {
        case 'create':
          return 'el-icon-circle-plus';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-button {
  padding: 0;
}
.footer {
  margin-top: 0.5em;
  text-align: right;
  line-height: 12px;
  span {
    padding: 0;
    margin: 0.8em 0 0 15px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
</style>
